var generic = generic || {};
var site = site || {};

site.sessionTimeout = site.sessionTimeout || {};

site.sessionTimeout = {
  signOutTimer: null,
  sessionIdlePopupTimer: null,
  sessionTimeoutCountDownTimer: null,
  signOutDuration: 15,
  sessionTimeoutRemainingTimeInSec: 20,
  locationProtocol: document.location.protocol,
  init: function (context) {
    this.clearSignOutTimer();
    this.setSignOutTimer(context);
  },

  getCookieValue: function (cookieFirst, cookieSecond) {
    cookieSecond = document.cookie.match('(^|;)\\s*' + cookieFirst + '\\s*=\\s*([^;]+)');

    return cookieSecond ? cookieSecond.pop() : '';
  },

  clearSignOutTimer: function () {
    var self = this;

    if (self.signOutTimer !== null) {
      clearTimeout(self.signOutTimer);
    }
  },
  setSignOutTimer: function (context) {
    var self = this;

    self.signOutTimer = setTimeout(function () {
      generic.overlay.hide();
      self.clearSessionCountDownTimer();
      self.signOutNow();
    }, self.signOutDuration * 60 * 1000);
    if (generic.cookie('LOCALE') === 'en_US') {
      self.sessionIdlePopup(context);
    }
  },

  sessionIdlePopup: function (context) {
    var self = this;

    if (self.sessionIdlePopupTimer !== null) {
      clearTimeout(self.sessionIdlePopupTimer);
    }
    self.sessionIdlePopupTimer = setTimeout(function () {
      // User needs to be reminded of session time-out only if he is logged-in
      if (site.userInfoCookie.getValue('signed_in') - 0 === 0) {
        return false;
      }
      var $sessionIdlePopUpContent = $($('script.inline-template[path="adjustable_time"]').html());
      var $sessionIdlePopUp = $('<div/>').append($sessionIdlePopUpContent).addClass('js-session-idle-popup-tab-order');

      // Show idle session alert pop-up
      generic.overlay.launch({
        content: $sessionIdlePopUp,
        includeBackground: true,
        overlayClose: false,
        width: '580px',
        height: '220px',
        closeButton: true,
        onComplete: function () {
          self.popupLinkEventBinding(context);
        }
      });

      var $cboxOverlay = $('#cboxOverlay', context);
      var $cboxContent = $('#cboxContent', context);

      $cboxOverlay.addClass('pre_chat_overlay_bg');
      $cboxContent.attr('tabindex', '0').focus();

      $.fn.escape = function (callback) {
        return this.each(function () {
          $(document).on('keydown', this, function (e) {
            var keycode = typeof e.keyCode != 'undefined' && e.keyCode ? e.keyCode : e.which;

            if (keycode === 32) {
              callback.call(this, e);
            }
          });
        });
      };

      $('.js-session-idle-popup-tab-order').escape(function () {
        $('.js-session-idle-popup-logout').trigger('click');
      });

      // Lets show countdown for the user to choose his options in sign-out alert pop-up
      self.clearSessionCountDownTimer();

      var sessionTimeoutCountDownCntr = 0,
        $sessionTimeoutCounter = $('.js-session-timeout-count-down', $('#colorbox'));

      $sessionTimeoutCounter.text(self.sessionTimeoutRemainingTimeInSec);
      self.sessionTimeoutCountDownTimer = setInterval(function () {
        sessionTimeoutCountDownCntr++;
        var sessionTimeoutString = self.sessionTimeoutRemainingTimeInSec - sessionTimeoutCountDownCntr;

        if (sessionTimeoutString > -1) {
          $sessionTimeoutCounter.text(sessionTimeoutString);
        }
      }, 1000);
    }, (self.signOutDuration - self.sessionTimeoutRemainingTimeInSec / 60) * 60 * 1000);
  },

  clearSessionCountDownTimer: function () {
    if (this.sessionTimeoutCountDownTimer !== null) {
      clearInterval(this.sessionTimeoutCountDownTimer);
    }
  },

  // Sign-out destination URL
  signOutNow: function () {
    if (site.userInfoCookie.getValue('signed_in') - 0 === 0) {
      return false;
    }
    document.location.href = '/account/signin.tmpl?_SUBMIT=signout';
  },

  sessionExtend: function (context) {
    generic.overlay.hide();
    this.clearSessionCountDownTimer();
    this.clearSignOutTimer();
    this.setSignOutTimer(context);

    // RPC Sent to Retain Session
    generic.jsonrpc.fetch({
      method: 'session.get',
      onSuccess: function () {
        // We have extended the session
      }
    });
  },

  popupLinkEventBinding: function (context) {
    // "Logout" CTA from session time-out alert pop-up
    $('.js-session-idle-popup-logout').on('click', function () {
      site.sessionTimeout.signOutNow();
    });
    // "Stay connected" CTA from session time-out alert pop-up
    $('.js-session-idle-popup-retain-session').on('click', function () {
      site.sessionTimeout.sessionExtend(context);
    });
  }
};
(function ($, Drupal) {
  Drupal.behaviors.adjustableTime = {
    attach: function (context) {
      if (site.sessionTimeout.locationProtocol === 'https:' && !site.sessionTimeout.getCookieValue('debug_noTimeout')) {
        // On page load clear any time-outs and start ticking a new one
        site.sessionTimeout.init(context);
      }
    }
  };
})(jQuery, Drupal);
